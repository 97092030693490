import React, { useState, createContext, useEffect } from "react";
import Cookies from "js-cookie";

export const ShopContext = createContext({});

export const ShopProvider = ({ children }) => {
  /*
   * Fetch cookied context state vars init values
   *
   */
  let cartInit = [];
  const cartFromCookies = Cookies.get("cart");
  if (cartFromCookies) cartInit = JSON.parse(cartFromCookies);

  // Fetch isPrivateCustomer from cookie and transform the string to a bool, then create the state variable
  let isPrivateCustomerFromCookie = Cookies.get("isPrivateCustomer");
  if (cartInit.length === 0) isPrivateCustomerFromCookie = undefined;
  let isPrivateCustomerInit;
  switch (isPrivateCustomerFromCookie) {
    case "true":
      isPrivateCustomerInit = true;
      break;
    case "false":
      isPrivateCustomerInit = false;
  }

  /*
   * Create the context states
   */
  const [cart, setCart] = useState(cartInit);
  const [isPrivateCustomer, setIsPrivateCustomer] = useState(
    isPrivateCustomerInit
  );

  const [rebatePercentage, setRebatePercentage] = useState(0);
  const [rebateDiscountAmount, setRebateDiscountAmount] = useState(0);
  const [rebateCode, setRebateCode] = useState("");

  /*
   * Functions definitions
   */
  const addCartItem = (productConfig) => {
    const edit = [...cart, productConfig];
    setCart(edit);
    Cookies.set("cart", JSON.stringify(edit));
  };

  const removeCartItem = (id) => {
    const edit = cart.filter((orderLine) => orderLine.id !== id);
    setCart(edit);
    Cookies.set("cart", JSON.stringify(edit));
    if (edit.length === 0) setIsPrivateCustomer(undefined);
  };

  const emptyCart = () => {
    const edit = [];
    setCart(edit);
    Cookies.set("cart", edit);
    setIsPrivateCustomer(undefined);

    removeRebateFromCart();
  };

  const emptyCartAndAddItem = (productConfig) => {
    const edit = [productConfig];
    setCart(edit);
    Cookies.set("cart", edit);

    removeRebateFromCart();
  };

  const updateIsPrivateCustomer = (type) => {
    const edit = type;
    setIsPrivateCustomer(edit);

    if (!edit) Cookies.remove("isPrivateCustomer");
    else Cookies.set("isPrivateCustomer", edit);
  };

  const addRebateToCart = (rebateObject) => {
    if (rebateCode) removeRebateFromCart();
    setRebateCode(rebateObject.code);
    switch (rebateObject.discount_type) {
      case "fixed_cart":
        setRebateDiscountAmount(rebateObject.amount);
        break;
      case "percent":
        setRebatePercentage(rebateObject.amount);
    }
  };

  const removeRebateFromCart = () => {
    setRebateCode("");
    setRebateDiscountAmount(0);
    setRebatePercentage(0);
  };

  return (
    <ShopContext.Provider
      value={{
        cart,
        isPrivateCustomer,

        addCartItem,
        removeCartItem,
        emptyCart,
        emptyCartAndAddItem,
        setIsPrivateCustomer: updateIsPrivateCustomer,
        addRebateToCart,
        rebatePercentage,
        rebateDiscountAmount,
        rebateCode,
        removeRebateFromCart,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

/*
 cart: 
    [
        {
            orderLine: {
                id: UID !!TBD!!
                package: {PRODUCT},
                date: {DATE},
                addons: [
                    {PRODUCT},
                    {PRODUCT}
                ],
                otherInfo: 'string'
                totalCost = {amount, currency, vat}
        }
    ]
}}
*/
