import "typeface-bebas-neue";
import "typeface-open-sans";
import "./src/styles/global.css";

/* to ShopContext */
import React from "react";
import { ShopProvider } from "./src/context/ShopContext";

export const wrapRootElement = ({ element }) => (
  <ShopProvider>{element}</ShopProvider>
);
