exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allmanna-villkor-js": () => import("./../../../src/pages/allmanna-villkor.js" /* webpackChunkName: "component---src-pages-allmanna-villkor-js" */),
  "component---src-pages-biggy-art-2-js": () => import("./../../../src/pages/biggy-art-2.js" /* webpackChunkName: "component---src-pages-biggy-art-2-js" */),
  "component---src-pages-biggy-art-js": () => import("./../../../src/pages/biggy-art.js" /* webpackChunkName: "component---src-pages-biggy-art-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-tack-js": () => import("./../../../src/pages/tack.js" /* webpackChunkName: "component---src-pages-tack-js" */),
  "component---src-pages-vanliga-fragor-js": () => import("./../../../src/pages/vanliga-fragor.js" /* webpackChunkName: "component---src-pages-vanliga-fragor-js" */),
  "component---src-templates-collections-js": () => import("./../../../src/templates/collections.js" /* webpackChunkName: "component---src-templates-collections-js" */),
  "component---src-templates-product-config-js": () => import("./../../../src/templates/productConfig.js" /* webpackChunkName: "component---src-templates-product-config-js" */)
}

